import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { combineLatest, firstValueFrom, lastValueFrom, Observable, Subscription, tap, throwError } from 'rxjs';
import { ForecastV3Service } from '../../services/forecastV3.service';
import {
	AddForecastDataToState,
	AddStreamDataToState,
	ClearForecastV3State,
	CreateForecast,
	CreateGlobalFactor,
	CreateStream,
	DeleteForecast,
	DeleteStream,
	DuplicateForecast,
	DuplicateStream,
	GetForecasts,
	GetStreams,
	InitForecastV3State,
	LazyLoadForecastData,
	LazyLoadStreamData,
	RetryLazyLoadForecastData,
	UpdateForecast,
	UpdateStream,
	UpdateStreamData,
} from '../actions/forecastV3.action';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { GlobalFactorItem, IFactor, IStreamGroup } from '../../models/forecast-forecast.model';
import { EditedForecastFactorGroups, EditedStreamGroups, ForecastValues, IForecast, StreamGroupPayload } from '../../models/forecast-forecast-response.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, ForecastV3UpdatedEvent } from 'src/app/shared/models/updated-events.model';
import { PreferencesFacadeService } from 'src/app/shared/services/facade/preferences.facade.service';
import { SnackType } from 'src/app/shared/models/snacks.model';
import { FeatureId } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import { IStream } from '../../models/forecast-stream.model';
import { DuplicateStreamPayload, PostForecastPayload } from '../../models/forecast-api.model';

export class ForecastV3StateModel {
	forecasts: IForecast[];
	streams: IStream[];
	preFetchInFlight: boolean;
}

@State<ForecastV3StateModel>({
	name: 'forecastV3',
	defaults: {
		forecasts: null,
		streams: null,
		preFetchInFlight: false,
	},
})
@Injectable()
export class ForecastV3State {
	private isInitialized: boolean;
	@Select(CustomerFeatureState.hasPermission(FeatureId.forecast)) shouldSeeForecasts: Observable<boolean>;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private forecastV3Service: ForecastV3Service,
		private preferencesFacadeService: PreferencesFacadeService,
		private updatedEventsService: UpdatedEventsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.isInitialized = false;
	}

	@Selector() static forecastsV3(state: ForecastV3StateModel): IForecast[] {
		return state.forecasts;
	}
	@Selector() static streamsV3(state: ForecastV3StateModel): IStream[] {
		return state.streams;
	}

	@Selector() static forecastsPreFetchInFlight(forecastState: ForecastV3StateModel): boolean {
		return forecastState.preFetchInFlight;
	}

	@Action(InitForecastV3State)
	async initForecastV3State(context: StateContext<ForecastV3StateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const forecastStateIsCached: boolean = this.forecastStateIsCached(deserializedState);
			const streamStateIsCached: boolean = this.streamStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.shouldSeeForecasts]).subscribe({
				next: ([appReady, shouldSeeForecasts]: [boolean, boolean]) => {
					if (!this.isInitialized && appReady && shouldSeeForecasts !== undefined) {
						if (shouldSeeForecasts) {
							if (forecastStateIsCached || streamStateIsCached) {
								const state: ForecastV3StateModel = deserializedState.forecastV3;
								context.patchState(state);
							}
							if (!forecastStateIsCached) {
								context.dispatch(new GetForecasts());
							}
							if (!streamStateIsCached) {
								context.dispatch(new GetStreams());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ forecasts: [], streams: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetForecasts)
	getForecasts(context: StateContext<ForecastV3StateModel>): Observable<HttpResponse<any>> {
		return this.forecastV3Service.getForecasts().pipe(
			tap((response: HttpResponse<any>) => {
				const forecasts: IForecast[] = response.body.forecasts;
				this.addForecastsToForecastState(context, forecasts);
			})
		);
	}

	@Action(GetStreams)
	getStreams(context: StateContext<ForecastV3StateModel>): Observable<HttpResponse<any>> {
		return this.forecastV3Service.getStreams().pipe(
			tap((response: HttpResponse<any>) => {
				const streams: IStream[] = response.body.streams;
				this.addStreamsToForecastState(context, streams);
			})
		);
	}

	@Action(CreateStream)
	createStream(context: StateContext<ForecastV3StateModel>, action: CreateStream): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamPayload: IStream = action.streamPayload;

				const newStreamResponse: HttpResponse<object> = await firstValueFrom(this.forecastV3Service.createStream(streamPayload));
				const streamDataResponse: HttpResponse<object> = await firstValueFrom(
					this.forecastV3Service.getStreamData(
						{
							streamId: <string>newStreamResponse.body['streamId'],
							currencyOverride: streamPayload.currency,
						},
						streamPayload.calendarSettings
					)
				);
				context.dispatch(new AddStreamDataToState(<IStream>streamDataResponse.body));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(DuplicateStream)
	duplicateStream(context: StateContext<ForecastV3StateModel>, action: DuplicateStream): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamPayload: DuplicateStreamPayload = action.streamPayload;

				const existingStreams: IStream[] = context.getState().streams;
				const duplicatedStream: IStream = existingStreams.find(stream => stream.streamId === streamPayload.duplicateStreamById);

				const newStreamResponse: HttpResponse<object> = await firstValueFrom(this.forecastV3Service.duplicateStream(streamPayload));
				const streamDataResponse: HttpResponse<object> = await firstValueFrom(
					this.forecastV3Service.getStreamData(
						{
							streamId: <string>newStreamResponse.body['streamId'],
							currencyOverride: duplicatedStream.currency,
						},
						duplicatedStream.calendarSettings
					)
				);
				context.dispatch(new AddStreamDataToState(<IStream>streamDataResponse.body));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(CreateForecast)
	createForecast(context: StateContext<ForecastV3StateModel>, action: CreateForecast): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				// Create New
				const forecastPayload: PostForecastPayload = action.forecastPayload;
				this.forecastV3Service.createForecast(forecastPayload).subscribe(result => {
					const forecastId: string = result.body['forecastId'];
					this.forecastV3Service
						.getForecastData(
							{
								forecastId: forecastId,
								cadence: forecastPayload.cadence,
								currency: forecastPayload.currency,
							},
							forecastPayload.calendarSettings
						)
						.subscribe(forecastResult => {
							context.dispatch(new AddForecastDataToState(<IForecast>forecastResult.body));
							resolve();
						});
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(DuplicateForecast)
	duplicateForecast(context: StateContext<ForecastV3StateModel>, action: DuplicateForecast): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				// Duplicate
				const duplicateForecastResponse: HttpResponse<object> = await firstValueFrom(
					this.forecastV3Service.duplicateForecast({
						duplicateForecastById: action.forecastPayload.duplicateForecastById,
						duplicateName: action.forecastPayload.duplicateName,
					})
				);
				const existingForecasts: IForecast[] = context.getState().forecasts;
				const duplicatedForecast: IForecast = existingForecasts.find(forecast => forecast.forecastId === action.forecastPayload.duplicateForecastById);
				if (duplicateForecastResponse && duplicateForecastResponse.body['forecastId']) {
					const newForecastId: string = duplicateForecastResponse.body['forecastId'];
					this.forecastV3Service
						.getForecastData(
							{
								forecastId: newForecastId,
								cadence: duplicatedForecast.cadence,
								currency: duplicatedForecast.currency,
							},
							duplicatedForecast.calendarSettings
						)
						.subscribe((result: HttpResponse<IForecast>) => {
							const getForecastDataResponse: IForecast = result.body;
							const state: ForecastV3StateModel = context.getState();
							const sortedForecasts: IForecast[] = this.sortForecastsByName([getForecastDataResponse, ...state.forecasts]);
							state.forecasts = sortedForecasts;
							context.patchState(state);
							resolve();
						});
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(LazyLoadForecastData)
	async lazyLoadForecastData(context: StateContext<ForecastV3StateModel>, action: LazyLoadForecastData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: ForecastV3StateModel = context.getState();
				const forecast: IForecast = state.forecasts.find((findForecast: IForecast) => findForecast.forecastId === action.forecastId);
				await this.lazyLoadForecastsData(context, forecast);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(LazyLoadStreamData)
	async lazyLoadStreamData(context: StateContext<ForecastV3StateModel>, action: LazyLoadStreamData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: ForecastV3StateModel = context.getState();
				const stream: IStream = state.streams.find((findStream: IStream) => findStream.streamId === action.streamId);
				await this.lazyLoadStreamsData(context, stream);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(CreateGlobalFactor)
	createGlobalFactor(context: StateContext<ForecastV3StateModel>, action: CreateGlobalFactor): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const globalFactorsFayload: IFactor = action.forecastFactorsPayload;
				const forecastId: string = action.forecastId;
				await lastValueFrom(this.forecastV3Service.createGlobalFactor(globalFactorsFayload, forecastId));
				await context.dispatch(new LazyLoadForecastData(forecastId));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateStreamData)
	updateStreamData(context: StateContext<ForecastV3StateModel>, action: UpdateStreamData) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamValuePayload: ForecastValues = action.streamValues;
				await firstValueFrom(this.forecastV3Service.updateStreamData(action.streamId, streamValuePayload));
				await context.dispatch(new LazyLoadStreamData(action.streamId));
				await this.updateForecastsForDeletedStreams(context, action.streamId, action.parentForecast);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateStream)
	updateStream(context: StateContext<ForecastV3StateModel>, action: UpdateStream) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamPayload: IStream = action.streamPayload;
				await firstValueFrom(this.forecastV3Service.updateStream(action.streamId, streamPayload));
				if (action.values) {
					await firstValueFrom(this.forecastV3Service.updateStreamData(action.streamId, action.values));
				}
				const streamResponse = await firstValueFrom(this.forecastV3Service.getStreams());
				const stream: IStream = <IStream>streamResponse.body['streams'].find(updatedStream => updatedStream.streamId === action.streamId);

				const streamDataResponse: HttpResponse<object> = await firstValueFrom(
					this.forecastV3Service.getStreamData(
						{
							streamId: action.streamId,
							currencyOverride: stream.currency,
						},
						stream.calendarSettings
					)
				);
				context.dispatch(new AddStreamDataToState(<IStream>streamDataResponse.body));
				await this.updateForecastsForDeletedStreams(context, action.streamId, action.parentForecast);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateForecast)
	updateForecast(context: StateContext<ForecastV3StateModel>, action: UpdateForecast): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const forecastPayload: Partial<PostForecastPayload> = action.forecastPayload;
				if (Object.keys(forecastPayload).length > 0) {
					await firstValueFrom(this.forecastV3Service.updateForecast(action.forecastId, forecastPayload));
				}
				if ((action.globalFactorsSource?.length && action.editedGlobalFactors) || action.editedGlobalFactors?.deletedGlobalFactorIds?.length) {
					await this.updateGlobalFactors(action.forecastId, action.globalFactorsSource, action.editedGlobalFactors);
				}
				if ((action.streamGroupsSource?.length && action.editedStreamGroups) || action.editedStreamGroups?.deletedStreamGroupIds?.length) {
					await this.updateStreamGroups(action.forecastId, action.streamGroupsSource, action.editedStreamGroups);
				}
				const forecastResponse: HttpResponse<IForecast[]> = await firstValueFrom(this.forecastV3Service.getForecasts());
				const forecast: IForecast = forecastResponse.body['forecasts'].find(updatedForecast => updatedForecast.forecastId === action.forecastId);

				const forecastDataResponse: HttpResponse<IForecast> = await firstValueFrom(
					this.forecastV3Service.getForecastData(
						{
							forecastId: action.forecastId,
							cadence: forecast.cadence,
							startDate: forecast.calendarSettings.startDate,
							endDate: forecast.calendarSettings.endDate,
							currency: forecast.currency,
						},
						forecast.calendarSettings
					)
				);
				const fullForecast: IForecast = forecastDataResponse.body;
				const stateCopy: ForecastV3StateModel = JSON.parse(JSON.stringify(context.getState()));
				const forecastIndex: number = stateCopy.forecasts.findIndex((filter: IForecast) => forecast.forecastId === filter.forecastId);

				await context.dispatch(new LazyLoadForecastData(fullForecast.forecastId));

				stateCopy.forecasts[forecastIndex] = fullForecast;

				context.patchState({ forecasts: stateCopy.forecasts });
				this.updateForecastsForDeletedForecasts(context, action.forecastId);
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	}

	async updateStreamGroups(forecastId: string, streamGroups: IStreamGroup[], editedStreamGroups: EditedStreamGroups): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const requests = [];
				const streamGroupsPayload: StreamGroupPayload[] = [];
				streamGroups.forEach(streamGroup => {
					const streamGroupPayload: StreamGroupPayload = {
						name: streamGroup.name,
						description: streamGroup.description,
						streamGroupId: streamGroup.streamGroupId,
						streams: streamGroup.streams.map(stream => stream.streamId),
					};
					streamGroupsPayload.push(streamGroupPayload);
				});

				if (editedStreamGroups.deletedStreamGroupIds.length > 0) {
					editedStreamGroups.deletedStreamGroupIds.forEach(streamGroupId => {
						requests.push(firstValueFrom(this.forecastV3Service.deleteStreamGroup(forecastId, streamGroupId)));
					});
				}
				if (editedStreamGroups.addedStreamGroupIds.length > 0) {
					editedStreamGroups.addedStreamGroupIds.forEach(streamGroupId => {
						const addedStreamGroup: StreamGroupPayload = streamGroupsPayload.find(streamGroup => streamGroup.streamGroupId === streamGroupId);
						if (addedStreamGroup) {
							delete addedStreamGroup.streamGroupId;
							requests.push(firstValueFrom(this.forecastV3Service.createStreamGroup(forecastId, addedStreamGroup)));
						}
					});
				}
				if (editedStreamGroups.updatedStreamGroupIds.length > 0) {
					editedStreamGroups.updatedStreamGroupIds.forEach(streamGroupId => {
						const updatedStreamGroup: StreamGroupPayload = streamGroupsPayload.find(streamGroup => streamGroup.streamGroupId === streamGroupId);
						if (updatedStreamGroup) {
							delete updatedStreamGroup.streamGroupId;
							requests.push(this.forecastV3Service.editStreamGroup(forecastId, streamGroupId, updatedStreamGroup).toPromise());
						}
					});
				}
				if (requests.length > 0) {
					await Promise.all(requests);
				}
				resolve();
			} catch (e) {
				reject(new Error('Could not update stream groups' + e?.toString()));
			}
		});
	}

	async updateGlobalFactors(forecastId: string, forecastFactors: GlobalFactorItem[], editedForecastFactors: EditedForecastFactorGroups): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const requests = [];
				const factorsPayload: IFactor[] = [];
				forecastFactors.forEach(factor => {
					const factorPayload: IFactor = {
						factorId: factor.factorId,
						name: factor.streamName ? factor.streamName : '',
						startDate: factor.startDate,
						endDate: factor.endDate,
						cadence: factor.factorInterval,
						type: factor.factorType,
						value: factor.factorValue,
						stream: factor.streamId,
					};
					factorPayload.factorId = factor.factorId;
					factorsPayload.push(factorPayload);
				});

				if (editedForecastFactors.deletedGlobalFactorIds.length > 0) {
					editedForecastFactors.deletedGlobalFactorIds.forEach(factorId => {
						requests.push(this.forecastV3Service.deleteGlobalFactor(forecastId, factorId).toPromise());
					});
				}
				if (editedForecastFactors.addedGlobalFactor.length > 0) {
					const newFactors: IFactor[] = factorsPayload.filter(f => !f.factorId);
					newFactors.forEach((newFactor: IFactor) => {
						this.forecastV3Service.createGlobalFactor(newFactor, forecastId);
					});
				}
				if (editedForecastFactors.updatedGlobalFactors.length > 0) {
					editedForecastFactors.updatedGlobalFactors.forEach((globalFactor: IFactor) => {
						const updatedFactor: IFactor = factorsPayload.find((factorInPayload: IFactor) => factorInPayload.factorId === globalFactor.factorId);
						const idForFunction: string = updatedFactor.factorId;
						updatedFactor.factorId = undefined;
						if (updatedFactor) {
							requests.push(this.forecastV3Service.editGlobalFactor(forecastId, idForFunction, updatedFactor).toPromise());
						}
					});
				}
				if (requests.length > 0) {
					await Promise.all(requests);
				}
				resolve();
			} catch (e) {
				reject(new Error('Could not update global factors' + e?.toString()));
			}
		});
	}

	@Action(DeleteStream)
	deleteStream(context: StateContext<ForecastV3StateModel>, action: DeleteStream): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamIdToDelete: string = action.streamId;
				this.forecastV3Service.deleteStream(streamIdToDelete).subscribe(() => {
					const state: ForecastV3StateModel = context.getState();
					const streams: IStream[] = state.streams;
					const i: number = streams.findIndex((stream: IStream) => stream.streamId === streamIdToDelete);
					if (i > -1) {
						streams.splice(i, 1);
					}
					state.streams = streams;
					context.patchState(state);
					this.updateForecastsForDeletedStreams(context, streamIdToDelete, action.parentForecast).then(() => {
						resolve();
					});
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(DeleteForecast)
	deleteForecast(context: StateContext<ForecastV3StateModel>, action: DeleteForecast): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const forecastToDelete: string = action.forecastId;
				const state: ForecastV3StateModel = context.getState();
				const forecasts: IForecast[] = state.forecasts;
				const i: number = forecasts.findIndex(forecast => forecast.forecastId === forecastToDelete);
				if (i > -1) {
					forecasts.splice(i, 1);
				}
				state.forecasts = forecasts;
				context.patchState(state);
				await firstValueFrom(this.forecastV3Service.deleteForecast(forecastToDelete));
				this.updatedEventsService.updateItem(new ForecastV3UpdatedEvent(ActionType.delete, action.forecastId));
				this.updateForecastsForDeletedForecasts(context, action.forecastId);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(RetryLazyLoadForecastData)
	retryLazyLoadForecastData(context: StateContext<ForecastV3StateModel>): void {
		this.lazyLoadVisibleForecastData(context);
	}

	private addForecastsToForecastState(context: StateContext<ForecastV3StateModel>, forecasts: IForecast[]): void {
		const state: ForecastV3StateModel = context.getState();
		if (forecasts && state.forecasts) {
			const newReportsToAdd: IForecast[] = forecasts.filter(
				(filterReport: IForecast) => !state.forecasts.find((findReport: IForecast) => filterReport.forecastId === findReport.forecastId)
			);
			if (newReportsToAdd.length) {
				state.forecasts = state.forecasts.concat(newReportsToAdd);
				context.patchState(state);
			}
		} else if (forecasts && !state.forecasts) {
			state.forecasts = forecasts;
			context.patchState(state);
			this.lazyLoadVisibleForecastData(context);
		}
	}

	private addStreamsToForecastState(context: StateContext<ForecastV3StateModel>, streams: IStream[]): void {
		const state: ForecastV3StateModel = context.getState();
		if (streams && state.streams) {
			const newStreamsToAdd: IStream[] = streams.filter(
				(filterStream: IStream) => !state.streams.find((findStream: IStream) => filterStream.streamId === findStream.streamId)
			);
			if (newStreamsToAdd.length) {
				state.streams = state.streams.concat(newStreamsToAdd);
				context.patchState(state);
			}
		} else if (streams && !state.streams) {
			state.streams = streams;
			context.patchState(state);
		}
	}

	private async updateForecastsForDeletedStreams(context: StateContext<ForecastV3StateModel>, streamId: string, parentForecast?: IForecast): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const state: ForecastV3StateModel = context.getState();
				if (parentForecast) {
					await this.lazyLoadForecastsData(context, parentForecast);
					resolve();
				}

				const affectedForecasts: IForecast[] = [];
				// Check Streams
				state.forecasts.forEach((forecast: IForecast) => {
					forecast.streams.forEach((stream: IStream) => {
						if (stream.streamId === streamId) {
							affectedForecasts.push(forecast);
						}
					});
				});

				// Check Stream Groups
				state.forecasts.forEach((forecast: IForecast) => {
					forecast.streamGroups.forEach((streamGroup: IStreamGroup) => {
						streamGroup.streams.forEach((stream: IStream) => {
							if (stream.streamId === streamId) {
								affectedForecasts.push(forecast);
							}
						});
					});
				});
				// Removes duplicate forecasts from this array
				const uniqueAffectedForecasts: IForecast[] = [...new Map(affectedForecasts.map((f: IForecast) => [f.forecastId, f])).values()];

				if (parentForecast) {
					const i: number = uniqueAffectedForecasts.findIndex((forecast: IForecast) => forecast.forecastId === parentForecast.forecastId);
					if (i >= 0) {
						uniqueAffectedForecasts.splice(i, 1);
					}
				}

				await Promise.all(uniqueAffectedForecasts.map((forecast: IForecast) => this.lazyLoadForecastsData(context, forecast).catch(error => new Error(error))));

				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	private async updateForecastsForDeletedForecasts(context: StateContext<ForecastV3StateModel>, forecastId: string): Promise<void> {
		const state: ForecastV3StateModel = context.getState();
		const affectedForecasts: IForecast[] = [];
		// Check child Forecasts
		state.forecasts.forEach((forecast: IForecast) => {
			forecast.streams.forEach((stream: IStream) => {
				if (stream.forecastId === forecastId) {
					affectedForecasts.push(forecast);
				}
			});
		});
		const uniqueAffectedForecasts: IForecast[] = [...new Map(affectedForecasts.map((f: IForecast) => [f.forecastId, f])).values()];
		await Promise.all(uniqueAffectedForecasts.map((forecast: IForecast) => this.lazyLoadForecastsData(context, forecast).catch(error => new Error(error))));
	}

	@Action(ClearForecastV3State)
	clearForecastV3State(context: StateContext<ForecastV3StateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: ForecastV3StateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private async lazyLoadVisibleForecastData(context: StateContext<ForecastV3StateModel>): Promise<void> {
		try {
			context.patchState({ preFetchInFlight: true });
			const visibleForecastIds: string[] = await this.preferencesFacadeService.getVisibleSnackIds(SnackType.forecastV3);
			const state: ForecastV3StateModel = context.getState();
			const forecastsToLoad: IForecast[] = [];
			state.forecasts.forEach((forecast: IForecast) => {
				if (!forecast.cashBalances && visibleForecastIds.includes(forecast.forecastId)) {
					forecastsToLoad.push(forecast);
				}
			});
			// by catching errors in promise all and returning this allows it to wait for all requests to either error out or complete succesfully
			await Promise.all(forecastsToLoad.map((forecast: IForecast) => this.lazyLoadForecastsData(context, forecast).catch(error => new Error(error))));
			context.patchState({ preFetchInFlight: false });
		} catch (error) {
			context.patchState({ preFetchInFlight: false });
		}
	}

	private lazyLoadForecastsData(context: StateContext<ForecastV3StateModel>, forecast: IForecast): Promise<void> {
		return new Promise((resolve, reject) => {
			const state: ForecastV3StateModel = context.getState();
			try {
				this.forecastV3Service
					.getForecastData(
						{
							forecastId: forecast.forecastId,
							cadence: forecast.cadence,
							currency: forecast.currency,
						},
						forecast.calendarSettings
					)
					.subscribe({
						next: (response: HttpResponse<IForecast>) => {
							const fullForecast: IForecast = response.body;
							const forecastIndex: number = state.forecasts.findIndex((filter: IForecast) => forecast.forecastId === filter.forecastId);
							state.forecasts[forecastIndex] = fullForecast;
							context.patchState({ forecasts: state.forecasts });
							resolve();
						},
						error: (error: HttpErrorResponse) => {
							forecast.errorMessage = error.message;
							reject(error);
						},
					});
			} catch (error) {
				forecast.errorMessage = error;
				reject(error);
			}
		});
	}

	private lazyLoadStreamsData(context: StateContext<ForecastV3StateModel>, stream: IStream): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				const state: ForecastV3StateModel = context.getState();
				this.forecastV3Service
					.getStreamData(
						{
							streamId: stream.streamId,
							currencyOverride: stream.currency,
						},
						stream.calendarSettings
					)
					.subscribe({
						next: (response: HttpResponse<IStream>) => {
							const fullStream: IStream = response.body;
							const streamIndex: number = state.streams.findIndex((filter: IStream) => stream.streamId === filter.streamId);
							state.streams[streamIndex] = fullStream;
							context.patchState({ streams: state.streams });
							resolve();
						},
						error: (error: HttpErrorResponse) => {
							stream.errorMessage = error.message;
						},
					});
			} catch (error) {
				stream.errorMessage = error;
				reject(error);
			}
		});
	}

	@Action(AddStreamDataToState)
	addStreamDataToState(context: StateContext<ForecastV3StateModel>, action: AddStreamDataToState) {
		const state: ForecastV3StateModel = context.getState();
		const streamsCopy: IStream[] = state.streams;
		const fullStream: IStream = action.stream;
		const i = streamsCopy.findIndex(stream => stream.streamId === fullStream.streamId);
		if (i >= 0) {
			streamsCopy[i] = fullStream;
		} else {
			streamsCopy.push(fullStream);
		}
		state.streams = this.sortStreamsByName(streamsCopy);
		context.patchState(state);
	}

	@Action(AddForecastDataToState)
	addForecastDataToState(context: StateContext<ForecastV3StateModel>, action: AddForecastDataToState): void {
		const state: ForecastV3StateModel = context.getState();
		const forecastsCopy: IForecast[] = state.forecasts;
		const fullForecast: IForecast = action.forecast;
		const i: number = forecastsCopy.findIndex((forecast: IForecast) => forecast.forecastId === fullForecast.forecastId);
		if (i >= 0) {
			forecastsCopy[i] = fullForecast;
		} else {
			forecastsCopy.push(fullForecast);
		}
		const sortedForecastsCopy: IForecast[] = this.sortForecastsByName(forecastsCopy);
		context.patchState({ forecasts: sortedForecastsCopy });
	}

	private forecastStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedForecastState: ForecastV3StateModel | undefined = deserializedState.forecastV3;
		if (deserializedForecastState && deserializedForecastState.forecasts) {
			return true;
		} else {
			return false;
		}
	}

	private streamStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedForecastState: ForecastV3StateModel | undefined = deserializedState.forecastV3;
		if (deserializedForecastState && deserializedForecastState.streams) {
			return true;
		} else {
			return false;
		}
	}

	private sortForecastsByName(forecasts: IForecast[]): IForecast[] {
		forecasts = forecasts.sort((forecastA: IForecast, forecastB: IForecast) => {
			if (forecastA.name.toLowerCase() < forecastB.name.toLowerCase()) {
				return -1;
			} else if (forecastA.name.toLowerCase() > forecastB.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return forecasts;
	}

	private sortStreamsByName(streams: IStream[]): IStream[] {
		streams = streams.sort((streamA: IStream, streamB: IStream) => {
			if (streamA.name.toLowerCase() < streamB.name.toLowerCase()) {
				return -1;
			} else if (streamA.name.toLowerCase() > streamB.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return streams;
	}
}
